import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react";
import { networks } from "../Networks";

type Props = {
  currentChainId: number | null;
  isNetworkSwitching: boolean;
  switchNetwork: (rpcUrl: string, chainId: number) => Promise<void>;
  setLoading: Dispatch<SetStateAction<boolean>>;
};

export default function NetworkSelectMagic({
  isNetworkSwitching,
  currentChainId,
  switchNetwork,
  setLoading,
}: Props) {
  const [selected, setSelected] = useState(networks[0]);
  const handleNetworkChange = async (selectedItem) => {
    setLoading(true);
    try {
      await switchNetwork(selectedItem.rpcUrl, selectedItem.value);
      setSelected(selectedItem);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const selectedNetwork = networks.find((n) => n.value === currentChainId);
    if (selectedNetwork) {
      setSelected(selectedNetwork);
    }
  }, [currentChainId]);
  return (
    <Menu as="div" className="relative -ml-px flex items-center">
      <div className="h-[80%] w-[0.75px] bg-dark-pri" />
      <Menu.Button
        disabled={isNetworkSwitching} // <--- disable if switching
        className={`h-[45px] relative inline-flex group items-center hover:brightness-110 rounded-r-md 
        bg-gradient-to-r from-primary to-dark-pri transition hover:bg-dark-pri/20 px-2 py-3 min-w-[36px] max-w-[36px] sm:max-w-none
        focus:z-10`}
      >
        <span className="sr-only">Open blockchain options</span>
        <span className="flex items-center">
          <img
            src={selected.image}
            alt=""
            className="h-5 w-5 min-w-5 object-cover sm:object-contain mr-1 group-hover:scale-105 rounded-full"
          />
        </span>
        <ChevronDownIcon
          className="h-4 w-4 opacity-80 hidden sm:flex"
          aria-hidden="true"
        />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 z-10 -mr-1 mt-[270px] w-56 origin-top-right rounded-lg bg-dark-default shadow-black/60 shadow-lg ring-1 
          ring-dark-pri/10 ring-opacity-5 focus:outline-none border border-dark-pri/10"
        >
          <div className="py-1">
            {networks.map((item, index) => (
              <Menu.Item key={item.label + index}>
                <div
                  onClick={() => handleNetworkChange(item)}
                  className="hover:bg-primary transition text-white px-4 py-2 text-sm flex cursor-pointer items-center gap-x-3"
                >
                  <img
                    src={item.image}
                    alt=""
                    className="h-5 w-5 flex-shrink-0 rounded-full "
                  />
                  {item.label}
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

"use client";

import { useSettingsNavigation } from "@/hooks/useSettingsNavigation";
import { Tooltip } from "@mui/material";
import { ImSpinner9 } from "react-icons/im";
import IconSettings from "../Icons/IconSettings";

export default function Settings() {
  const { goToSettings, handleSettingsClick } = useSettingsNavigation();
  return (
    <>
      {/* @ts-ignore */}
      <Tooltip title="Settings" arrow placement="right">
        <button
          className="mx-auto "
          onClick={handleSettingsClick}
          aria-label="Go to Settings"
        >
          {!goToSettings ? (
            <IconSettings />
          ) : (
            <ImSpinner9 className="text-dark-pri/70 animate-spin w-6 h-6" />
          )}
        </button>
      </Tooltip>
    </>
  );
}

import LoadingDots from "@/components/ui/Loading/LoadingDots";
import React from "react";

export default function SwitchingNetworkLoader() {
  return (
    <span className="flex justify-center items-center">
      <span className="text-sm hidden md:flex">Switching…</span>
      <span className="text-sm  md:hidden">
        <LoadingDots showThirdDot={false} />
      </span>
    </span>
  );
}

"use client";
import { BtnRainbowKit } from "@/components/magic/auth/BtnRainbowKit";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";
import { useMagic } from "@/context/MagicProvider";
import { logoutUser } from "@/server/actions/user/auth/logout";
import { useAccountModal } from "@rainbow-me/rainbowkit";
import { useState } from "react";
import { useAccount } from "wagmi";
import IconWallet from "../../Icons/IconWallet";
import LoadingDots from "../../Loading/LoadingDots";
import NetworkSelectMagic from "./Magic/NetworkSelect";
import SwitchingNetworkLoader from "./Magic/SwitchingNetworkLoader";

type Props = {
  wallet?: string;
  avatar?: string;
};

export default function BtnWalletLogin({ wallet, avatar }: Props) {
  const { magic, currentChainId, switchNetwork, isNetworkSwitching } =
    useMagic();
  const [loading, setLoading] = useState(false);
  const { setOpenLoginModal } = useLoginModalStore();
  const { openAccountModal } = useAccountModal();
  const { address, isConnected } = useAccount();

  const handleLoginBtnClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // if (isConnected || (wallet && wallet.length > 0) || openAccountModal) {
      if (wallet && wallet.length > 0) {
        // Open the Magic wallet modal
        await magic?.wallet.showUI();
      } else {
        await logoutUser();
        setOpenLoginModal(true);
      }
    } catch {
      const isLoggedInToMagic = await magic?.user.isLoggedIn();
      if (!isLoggedInToMagic && !isConnected) {
        await logoutUser();
        setOpenLoginModal(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="inline-flex rounded-md shadow-sm group md:min-w-[100px]">
      {/* User is logged in with Rainbowkit */}
      {openAccountModal ? (
        <BtnRainbowKit avatar={avatar} />
      ) : (
        !openAccountModal && (
          <button
            type="button"
            onClick={handleLoginBtnClick}
            disabled={isNetworkSwitching}
            aria-label="Open wallet"
            className={`sm:flex h-[45px] flex-grow overflow-clip items-center justify-center 
            border border-transparent px-4 sm:px-6 bg-primary py-2 text-base font-medium 
            hover:brightness-110 focus:outline-none transition ${
              wallet && wallet.length > 0
                ? "rounded-l-lg  w-[64px] sm:w-[120px] "
                : "rounded-lg w-[80px] sm:w-[80px]"
            } ${isNetworkSwitching && "cursor-not-allowed"}`}
          >
            {/* MAGIC WALLET BUTTON STATES */}
            {isNetworkSwitching ? (
              <SwitchingNetworkLoader />
            ) : loading ? (
              <LoadingDots showThirdDot={false} />
            ) : wallet && wallet.length > 0 ? (
              <span className="w-full sm:flex items-center justify-center sm:gap-x-2 md:min-w-[80px]">
                <IconWallet className="max-w-[26px] min-w-[26px]" />
                <span className="hidden sm:flex sm:min-w-[50px]">Wallet</span>
              </span>
            ) : (
              // LOG IN BUTTON (opens login modal)
              <span className="flex justify-center">Log in</span>
            )}
          </button>
        )
      )}

      {/* If using RainbowKit */}
      {wallet && wallet.length > 0 && !openAccountModal && (
        <NetworkSelectMagic
          isNetworkSwitching={isNetworkSwitching}
          currentChainId={currentChainId}
          switchNetwork={switchNetwork}
          setLoading={setLoading}
        />
      )}
    </div>
  );
}

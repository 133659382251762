"use client";

import { useLogout } from "@/hooks/useLogout";
import { useSettingsNavigation } from "@/hooks/useSettingsNavigation";
import { getWalletAddrAndAvatarFromJwt } from "@/server/actions/user/profileMetadata/getWalletAddrAndAvatarFromJwt";
import { AnimatePresence, motion } from "framer-motion";
import {
  CircleHelp,
  Gem,
  Mail,
  MessageCircleMore,
  Settings,
  Sword,
  Tag,
  UserRound,
} from "lucide-react";
import { useRouter } from "next/navigation";
import { useState } from "react";
import { IoGameControllerOutline } from "react-icons/io5";
import { ImSpinner9 } from "react-icons/im";
import { create } from "zustand";
import IconCloseX from "../../Icons/IconCloseX";
import IconHeart from "../../Icons/IconHeart";
import IconMenu from "../../Icons/IconMenu";
import LoadingDots from "../../Loading/LoadingDots";
import {
  fadeInVariant,
  hideNavItemsVariant,
  liVariant,
  mobileMenuVariant,
  ulVariant,
} from "./animation";
import { FORUM, LEGAL_POLICIES } from "./NavItems/static-links";
import styles from "./style.module.scss";

interface MobileMenuState {
  mobileMenu: boolean;
  setMobileMenu: (mobileMenu: boolean) => void;
}

export const useMobileMenuStore = create<MobileMenuState>((set) => ({
  mobileMenu: false,
  setMobileMenu: (mobileMenu) => set({ mobileMenu }),
}));

// Updated “Settings” item so that we rely on the hook (no direct href).
const MOBILE_NAV_ITEMS = [
  {
    id: 0,
    title: "Profile & Inventory",
    icon: (
      <UserRound className=" mx-auto" size={34} strokeWidth={1} />
      // <Sword className="rotate-[70deg] mx-auto" size={34} strokeWidth={1} />
    ),
    needsOnClick: true, // calls handleMyInventory
  },
  {
    id: 1,
    title: "Play Games",
    icon: <IoGameControllerOutline className="mx-auto text-[34px]" />,
    href: "/games",
  },
  {
    id: 2,
    title: "Sell",
    icon: <Tag className="mx-auto" size={34} strokeWidth={1} />,
    href: "/sell",
  },
  {
    id: 3,
    title: "Rewards",
    icon: <Gem className="mx-auto text-xl" size={34} strokeWidth={1} />,
    href: "/rewards",
  },
  {
    id: 4,
    title: "Likes",
    icon: (
      <div className="mx-auto">
        <IconHeart style="w-8 h-8" />
      </div>
    ),
    href: "/likes",
  },
  {
    id: 5,
    title: "Chat",
    icon: <MessageCircleMore size={34} strokeWidth={1} className="mx-auto" />,
    href: "/chat",
  },
  // Use the new settings hook for item #6
  {
    id: 6,
    title: "Settings",
    icon: <Settings className="mx-auto" size={34} strokeWidth={1} />,
    needsOnClick: true, // calls handleSettingsClick from our hook
  },
  {
    id: 7,
    title: "Contact",
    icon: <Mail className="mx-auto" size={34} strokeWidth={1} />,
    href: "/contact",
  },
  {
    id: 8,
    title: "Help",
    icon: <CircleHelp className="mx-auto" size={34} strokeWidth={1} />,
    href: "https://help.rodeyo.com/",
  },
];

const MobileMenu = () => {
  const { mobileMenu, setMobileMenu } = useMobileMenuStore();
  const router = useRouter();

  // Inventory logic
  const [loadingMyInventory, setLoadingMyInventory] = useState(false);
  const handleMyInventory = async () => {
    setLoadingMyInventory(true);
    try {
      const { wallet } = await getWalletAddrAndAvatarFromJwt();
      if (wallet && wallet.length > 0) {
        router.push(`/profile/${wallet}`);
      } else {
        router.push("/search");
      }
    } catch (error) {
      console.error("Error navigating to inventory");
    } finally {
      setMobileMenu(false);
      setLoadingMyInventory(false);
    }
  };

  // Generic link navigation
  const handleLinkClick = (href: string) => {
    router.push(href);
    setMobileMenu(false);
  };

  // Logout logic
  const { handleLogout, loggingOut } = useLogout();

  // 1) Use the settings navigation hook
  const { goToSettings, handleSettingsClick } = useSettingsNavigation();

  return (
    <>
      {/* Button to open the mobile menu */}
      <motion.button
        variants={hideNavItemsVariant}
        onClick={() => setMobileMenu(true)}
        className="p-3 cursor-pointer sm:hidden"
      >
        <IconMenu />
      </motion.button>

      <AnimatePresence>
        {mobileMenu && (
          <motion.nav
            initial="closed"
            animate="opened"
            exit="closed"
            className="sm:hidden fixed inset-0 flex"
          >
            <motion.div
              variants={mobileMenuVariant}
              className={styles.menuContent}
            >
              {loadingMyInventory && <LoadingDots />}

              {/* Logo */}
              <motion.div
                variants={fadeInVariant}
                className="fixed top-10 left-1/2 -translate-x-1/2 z-30 "
              >
                <div className="relative">
                  <div
                    className="-z-10 absolute inset-0 w-[60px] h-[60px] rounded-full 
                  bg-gradient-to-r from-pink via-primary to-teal-300 blur-2xl"
                  />
                  <img
                    src="/logo-circle.png"
                    alt="Rodeyo Logo"
                    className="w-[70px] h-auto z-30 "
                  />
                </div>
              </motion.div>

              {/* Close button */}
              <motion.button
                variants={fadeInVariant}
                onClick={() => setMobileMenu(false)}
                className="fixed -top-[10px] -right-[10px] z-50 p-5 rounded-full bg-primary "
              >
                <IconCloseX />
              </motion.button>

              {/* Icons Grid */}
              <motion.ul variants={ulVariant} className={styles.iconsGrid}>
                {MOBILE_NAV_ITEMS.map((navItem) => (
                  <motion.li
                    key={navItem.id}
                    whileTap={{ scale: 0.95 }}
                    variants={liVariant}
                  >
                    {navItem.needsOnClick ? (
                      <div
                        onClick={
                          navItem.title === "Settings"
                            ? async () => {
                                await handleSettingsClick();
                                setMobileMenu(false);
                              }
                            : handleMyInventory
                        }
                        className="flex flex-col items-center cursor-pointer"
                      >
                        {/* Show a spinner if we're navigating to Settings */}
                        <div className={styles.iosIcon}>
                          {navItem.title === "Settings" && goToSettings ? (
                            <ImSpinner9 className="text-dark-pri/70 animate-spin w-6 h-6 mx-auto" />
                          ) : (
                            navItem.icon
                          )}
                        </div>
                        <div className="text-xs leading-[18px] pt-3 pb-1 text-center text-gray-200 mx-auto max-w-[60px]">
                          {navItem.title}
                        </div>
                      </div>
                    ) : (
                      // Normal link navigation
                      <a
                        href={navItem.href}
                        onClick={(e) => {
                          e.preventDefault();
                          handleLinkClick(navItem.href!);
                        }}
                        className="flex flex-col items-center"
                      >
                        <div className={styles.iosIcon}>{navItem.icon}</div>
                        <div className="text-xs pt-3 pb-1 text-center text-gray-200">
                          {navItem.title}
                        </div>
                      </a>
                    )}
                  </motion.li>
                ))}
              </motion.ul>

              {/* Extra items: logout, forum links, etc. */}
              <div className="mt-12" />
              <motion.div variants={fadeInVariant} className={styles.contact}>
                <div onClick={handleLogout}>
                  {loggingOut ? <LoadingDots /> : "Log out"}
                </div>
                {FORUM.map((item) => (
                  <div
                    key={item.navTitle}
                    onClick={() => handleLinkClick(item.href)}
                  >
                    {item.navTitle}
                  </div>
                ))}
              </motion.div>

              <div className="mt-6" />
              <motion.div variants={fadeInVariant} className={styles.contact}>
                {LEGAL_POLICIES.map((policy) => (
                  <div
                    key={policy.navTitle}
                    onClick={() => handleLinkClick(policy.href)}
                  >
                    {policy.navTitle}
                  </div>
                ))}
              </motion.div>
            </motion.div>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileMenu;

"use client";

import { useState } from "react";
import { useRouter } from "next/navigation";
import { useAccount } from "wagmi";
import { useMagic } from "@/context/MagicProvider";
import { useLoginModalStore } from "@/components/magic/auth/LoginModal/ModalWrapper";

export function useSettingsNavigation() {
  const [goToSettings, setGoToSettings] = useState(false);
  const { magic } = useMagic();
  const { isConnected } = useAccount();
  const { setOpenLoginModal } = useLoginModalStore();
  const router = useRouter();

  const handleSettingsClick = async () => {
    setGoToSettings(true);

    // If already connected to a wallet, just go to settings.
    if (isConnected) {
      router.push("/settings");
      setGoToSettings(false);
      return;
    }

    // If not connected, then check Magic login status.
    const loggedIn = await magic?.user?.isLoggedIn();
    if (loggedIn) {
      router.push("/settings");
    } else {
      setOpenLoginModal(true);
    }

    setGoToSettings(false);
  };

  return {
    goToSettings,
    handleSettingsClick,
  };
}

"use client";
import { useLogout } from "@/hooks/useLogout";
import Tooltip from "@mui/material/Tooltip";
import { ImSpinner9 } from "react-icons/im";
import IconPower from "../Icons/IconPower";

export default function Logout() {
  const { handleLogout, loggingOut } = useLogout();

  return (
    <Tooltip title="Log out" arrow placement="right">
      <button className="mx-auto" onClick={handleLogout} aria-label="Log out">
        {loggingOut ? (
          <ImSpinner9 className="text-beige animate-spin w-6 h-6" />
        ) : (
          <IconPower />
        )}
      </button>
    </Tooltip>
  );
}

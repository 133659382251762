"use client";
import { usePathname } from "next/navigation";
import { Suspense, useEffect, useState } from "react";
import BtnLogo from "./BtnLogo";
import MobileMenu from "./MobileMenu";
import Play from "./Play";
import SellBtn from "./SellBtn";
import HpFilters from "../Homepage/NetworkFilter";

export function Header({ children }: { children: React.ReactNode }) {
  const path = usePathname();
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    let prevScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < prevScrollY) {
        // Scrolling up
        setShowHeader(true);
      } else if (currentScrollY > prevScrollY) {
        // Scrolling down
        setShowHeader(false);
      }
      prevScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`bg-dark-default/40 backdrop-blur-md top-0 shadow-xl z-40 
      w-full fixed pl-3 sm:px-6 xl:px-10  py-3   transition-transform duration-300 ease-in-out
      ${(path === "/create" || path?.includes("/games/") || path?.includes("/chat")) && "hidden"} ${
        showHeader ? "translate-y-0" : "md:-translate-y-full"
      }`}
      >
        <div
          className="w-full grid grid-cols-4 
      sm:grid-cols-3 sm:flex items-center justify-between"
        >
          <BtnLogo />

          <div
            className="flex items-center gap-x-4 md:gap-x-6 text-sm 
        justify-end col-span-2 sm:col-span-1 "
          >
            <Play />
            <Suspense>
              <SellBtn />
            </Suspense>
            {children}
            <MobileMenu />
          </div>
        </div>
      </div>
      <Suspense>
        {path === "/" && (
          <div
            className={`z-30 transition fixed bottom-4 md:bottom-2 left-1/2 -translate-x-1/2 ${
              showHeader ? "" : "hidden"
            }`}
          >
            <HpFilters />
          </div>
        )}
      </Suspense>
    </>
  );
}

"use client";

import { useState, useEffect } from "react";
import { pusherClient } from "@/lib/pusherClient";
import Image from "next/image";
import NumberTicker from "../../number-ticker";
import Link from "next/link";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { useRouter } from "next/navigation";
import { useAlertStore } from "../../Alert";

type RealTimeRewardsProps = {
  wallet: string;
  initialPoints: number;
};

export default function RealTimeRewards({
  wallet,
  initialPoints,
}: RealTimeRewardsProps) {
  const [points, setPoints] = useState<number>(initialPoints);
  const { setAlertMessage, setAlertType, setOpenAlert } = useAlertStore();
  // const router = useRouter();
  useEffect(() => {
    // Subscribe to the private channel unique to this user
    const channel = pusherClient.subscribe(`private-wallet-${wallet}`);

    // Listen for "points-updated" event
    channel.bind("points-updated", (data: { points: number }) => {
      setPoints(data.points);
      setAlertType("default");
      setAlertMessage("You've earned some points!");
      setOpenAlert(true);
    });

    // Cleanup when component unmounts or wallet changes
    return () => {
      channel.unbind("points-updated");
      pusherClient.unsubscribe(`private-wallet-${wallet}`);
    };
  }, [wallet]);

  // Just render your existing Rewards UI
  return (
    <div className="relative grid gap-y-[8px] mt-12 group">
      <Tooltip title="Rewards" arrow placement="right">
        <Link href="/rewards">
          <div className="bg-pink/50 blur-xl absolute object-contain w-[28px] h-[24px] mx-auto" />
          <Image
            src="/gem.png"
            width={28}
            height={28}
            alt="Rewards Gem Icon"
            className="group-hover:scale-125 object-contain w-[28px] mx-auto transition-all duration-300 ease-in-out"
          />
        </Link>
      </Tooltip>
      {points && points > 0 ? (
        <div className="w-full text-center text-sm font-semibold flex items-center justify-center">
          <span
            className="bg-gradient-to-r from-white to-dark-pri 
      bg-clip-text text-transparent"
          >
            <NumberTicker value={points} />
          </span>
        </div>
      ) : null}
    </div>
  );
}

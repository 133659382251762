import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
} from "@/constants/chains";

export const networks = [
  {
    label: "Polygon",
    value: POLYGON_MAINNET.chainId,
    image: `/network/${POLYGON_MAINNET.chainId}.png`,
    rpcUrl: POLYGON_MAINNET.rpcUrl,
  },
  {
    label: "Ethereum",
    value: ETHEREUM_MAINNET.chainId,
    image: `/network/${ETHEREUM_MAINNET.chainId}.png`,
    rpcUrl: ETHEREUM_MAINNET.rpcUrl,
  },
  {
    label: "Arbitrum",
    value: ARBITRUM_MAINNET.chainId,
    image: `/network/${ARBITRUM_MAINNET.chainId}.png`,
    rpcUrl: ARBITRUM_MAINNET.rpcUrl,
  },
  {
    label: "Base",
    value: BASE_MAINNET.chainId,
    image: `/network/${BASE_MAINNET.chainId}.png`,
    rpcUrl: BASE_MAINNET.rpcUrl,
  },
  {
    label: "Optimism",
    value: OPTIMISM_MAINNET.chainId,
    image: `/network/${OPTIMISM_MAINNET.chainId}.png`,
    rpcUrl: OPTIMISM_MAINNET.rpcUrl,
  },
];

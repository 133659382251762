import {
  CircleHelp,
  Gem,
  Mail,
  MessageCircleMore,
  Settings,
  Sword,
  Tag,
} from "lucide-react";
import IconHeart from "../../../Icons/IconHeart";
import { IoGameControllerOutline } from "react-icons/io5";

export const LEGAL_POLICIES = [
  {
    navTitle: "Terms",
    href: "/policies/terms",
  },
  {
    navTitle: "Privacy",
    href: "/policies/privacy",
  },
  {
    navTitle: "Copyright",
    href: "/policies/copyright",
  },
];

export const FORUM = [
  {
    navTitle: "forum",
    href: "https://help.rodeyo.com/",
  },
];
